import { default as _91agent_93pxrL4iOhkOMeta } from "/vercel/path0/pages/agenci-ubezpieczeniowi/[city]/[agent].vue?macro=true";
import { default as ankietaWXfLNleI8SMeta } from "/vercel/path0/pages/ankieta.vue?macro=true";
import { default as banki_45dla_45ukrainytCykQnX9fsMeta } from "/vercel/path0/pages/banki-dla-ukrainy.vue?macro=true";
import { default as index9gPBA2r7YBMeta } from "/vercel/path0/pages/bezpieczny-kredyt/index.vue?macro=true";
import { default as termin_45konsultacjiYp3T2zvOKyMeta } from "/vercel/path0/pages/bezpieczny-kredyt/termin-konsultacji.vue?macro=true";
import { default as indexKgvTMxxXnSMeta } from "/vercel/path0/pages/corp/index.vue?macro=true";
import { default as designZ1nB18UMQkMeta } from "/vercel/path0/pages/design.vue?macro=true";
import { default as dolacz_45do_45nashZyDL6k4pVMeta } from "/vercel/path0/pages/dolacz-do-nas.vue?macro=true";
import { default as indexyWIuPlQSffMeta } from "/vercel/path0/pages/eksperci-finansowi/[city]/[...agent]/index.vue?macro=true";
import { default as termin_45konsultacjimsntL5fS0GMeta } from "/vercel/path0/pages/eksperci-finansowi/[city]/[...agent]/termin-konsultacji.vue?macro=true";
import { default as indexwxyMc6x0f4Meta } from "/vercel/path0/pages/eksperci-finansowi/index.vue?macro=true";
import { default as indexp7pbTliZK5Meta } from "/vercel/path0/pages/elite/index.vue?macro=true";
import { default as ever_45aheadqS8iOQWFJ3Meta } from "/vercel/path0/pages/ever-ahead.vue?macro=true";
import { default as formularz_45kontaktowycguRA7lBpfMeta } from "/vercel/path0/pages/formularz-kontaktowy.vue?macro=true";
import { default as indexrIu7ZD2NV2Meta } from "/vercel/path0/pages/formularz/[type]/[...name]/index.vue?macro=true";
import { default as termin_45konsultacjiAie54kmMStMeta } from "/vercel/path0/pages/formularz/[type]/[...name]/termin-konsultacji.vue?macro=true";
import { default as globalHQJLhbd0A6Meta } from "/vercel/path0/pages/global.vue?macro=true";
import { default as _91roomId_93Od0NsftNZvMeta } from "/vercel/path0/pages/imobiliare/meet/[roomId].vue?macro=true";
import { default as _91uuid_937GGSO2NxoPMeta } from "/vercel/path0/pages/imobiliare/teleconference/[uuid].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as informacje_45o_45produktach_45hipotecznychohT2tPkGVFMeta } from "/vercel/path0/pages/informacje-o-produktach-hipotecznych.vue?macro=true";
import { default as indexuxB7QYRBueMeta } from "/vercel/path0/pages/kalkulator-zdolnosci-kredytowej/index.vue?macro=true";
import { default as oferta7pPytkjA1tMeta } from "/vercel/path0/pages/kalkulator-zdolnosci-kredytowej/termin-konsultacji/oferta.vue?macro=true";
import { default as zdolnoscGwjzegeDirMeta } from "/vercel/path0/pages/kalkulator-zdolnosci-kredytowej/termin-konsultacji/zdolnosc.vue?macro=true";
import { default as indexzIQYbJejyvMeta } from "/vercel/path0/pages/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego/index.vue?macro=true";
import { default as termin_45konsultacjiOrx6viRgtKMeta } from "/vercel/path0/pages/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego/termin-konsultacji.vue?macro=true";
import { default as indexz6G1PfFTUMMeta } from "/vercel/path0/pages/konsultacja-z-ekspertem/index.vue?macro=true";
import { default as kalkulator_45kredytu_45hipotecznegomgp7pGNbtNMeta } from "/vercel/path0/pages/konsultacja-z-ekspertem/kalkulator-kredytu-hipotecznego.vue?macro=true";
import { default as indexgIdZY6iDwkMeta } from "/vercel/path0/pages/konsultacja/[type]/index.vue?macro=true";
import { default as indexYjGUvsUW6wMeta } from "/vercel/path0/pages/konsultacje-z-ekspertem/index.vue?macro=true";
import { default as index3JIKLwKmY2Meta } from "/vercel/path0/pages/kredyt-hipoteczny/[bankSeoName]/index.vue?macro=true";
import { default as indexDbjZf3hOK5Meta } from "/vercel/path0/pages/kredyt-na-start/index.vue?macro=true";
import { default as termin_45konsultacjioJY4S90faZMeta } from "/vercel/path0/pages/kredyt-na-start/termin-konsultacji.vue?macro=true";
import { default as index0HjU9WVYx5Meta } from "/vercel/path0/pages/kredyty-gotowkowe/index.vue?macro=true";
import { default as ofertazOr71aAfmDMeta } from "/vercel/path0/pages/kredyty-gotowkowe/termin-konsultacji/oferta.vue?macro=true";
import { default as indexauXuJiB6LrMeta } from "/vercel/path0/pages/kredyty-hipoteczne/index.vue?macro=true";
import { default as ofertaf8R6tERJfAMeta } from "/vercel/path0/pages/kredyty-hipoteczne/termin-konsultacji/oferta.vue?macro=true";
import { default as BaseInstallmentPicker8K1ZxenVxRMeta } from "/vercel/path0/pages/lendigety/BaseInstallmentPicker.vue?macro=true";
import { default as CreditworthinessOffersWidgetWW1AQHWQ7DMeta } from "/vercel/path0/pages/lendigety/CreditworthinessOffersWidget.vue?macro=true";
import { default as expert_45contact_45no_45faqe2KlGYIqzDMeta } from "/vercel/path0/pages/lendigety/expert-contact-no-faq.vue?macro=true";
import { default as expert_45contactVtHZbNRXUFMeta } from "/vercel/path0/pages/lendigety/expert-contact.vue?macro=true";
import { default as FlatForStartFormWidgetOX6wZlBsIjMeta } from "/vercel/path0/pages/lendigety/FlatForStartFormWidget.vue?macro=true";
import { default as FlatForStartWidget0YVMFdVWwcMeta } from "/vercel/path0/pages/lendigety/FlatForStartWidget.vue?macro=true";
import { default as HalfpageBannerzEsIKlQqjbMeta } from "/vercel/path0/pages/lendigety/HalfpageBanner.vue?macro=true";
import { default as LightComparerWidgetrv3Kwk0djMMeta } from "/vercel/path0/pages/lendigety/LightComparerWidget.vue?macro=true";
import { default as mbwWidgetwXj9Rmq6G8Meta } from "/vercel/path0/pages/lendigety/mbwWidget.vue?macro=true";
import { default as MeetingPlannerWidgetGlZFriDNA0Meta } from "/vercel/path0/pages/lendigety/MeetingPlannerWidget.vue?macro=true";
import { default as MortgageComparerFormWidgetBuHlSXY4TbMeta } from "/vercel/path0/pages/lendigety/MortgageComparerFormWidget.vue?macro=true";
import { default as NaviboxBannerXRXAl0Dj9rMeta } from "/vercel/path0/pages/lendigety/NaviboxBanner.vue?macro=true";
import { default as RefinanceWidgetpiDc2mqtjUMeta } from "/vercel/path0/pages/lendigety/RefinanceWidget.vue?macro=true";
import { default as SafeLoanFormWidget2yGPO9cSo2Meta } from "/vercel/path0/pages/lendigety/SafeLoanFormWidget.vue?macro=true";
import { default as SafeLoanWidgetiabVseDC6wMeta } from "/vercel/path0/pages/lendigety/SafeLoanWidget.vue?macro=true";
import { default as SimulationFormBusinessInsiderBaWjmCvp4dMeta } from "/vercel/path0/pages/lendigety/SimulationFormBusinessInsider.vue?macro=true";
import { default as SimulationFormFlatForStart4l6LuFkEhLMeta } from "/vercel/path0/pages/lendigety/SimulationFormFlatForStart.vue?macro=true";
import { default as SimulationFormMortgageRlrgpyQpiBMeta } from "/vercel/path0/pages/lendigety/SimulationFormMortgage.vue?macro=true";
import { default as SimulationFormSafeLoanm9cuIneq7nMeta } from "/vercel/path0/pages/lendigety/SimulationFormSafeLoan.vue?macro=true";
import { default as WideboardBannerYDOwgz3xU3Meta } from "/vercel/path0/pages/lendigety/WideboardBanner.vue?macro=true";
import { default as _91roomId_930IoN8zWT1PMeta } from "/vercel/path0/pages/meet/[roomId].vue?macro=true";
import { default as indexKczu9ggIhTMeta } from "/vercel/path0/pages/michelin/index.vue?macro=true";
import { default as o_45nasUroN3GGTBZMeta } from "/vercel/path0/pages/o-nas.vue?macro=true";
import { default as indexFTWldDIAL7Meta } from "/vercel/path0/pages/orange/index.vue?macro=true";
import { default as indexYOqVBlskrvMeta } from "/vercel/path0/pages/placowki/[city]/index.vue?macro=true";
import { default as indexYjnUi7Ml9PMeta } from "/vercel/path0/pages/placowki/index.vue?macro=true";
import { default as termin_45konsultacji1deCgTrI7aMeta } from "/vercel/path0/pages/placowki/termin-konsultacji.vue?macro=true";
import { default as poradnik_45kredytowytSjsQlAh47Meta } from "/vercel/path0/pages/poradnik-kredytowy.vue?macro=true";
import { default as indexYN8S4UYwhpMeta } from "/vercel/path0/pages/rasp/index.vue?macro=true";
import { default as sukces2moB0XSHhpMeta } from "/vercel/path0/pages/rasp/sukces.vue?macro=true";
import { default as reklamacjevKsbLomG9eMeta } from "/vercel/path0/pages/reklamacje.vue?macro=true";
import { default as indexQVzflSC01SMeta } from "/vercel/path0/pages/rodzinny-kredyt-mieszkaniowy/index.vue?macro=true";
import { default as termin_45konsultacjiFmaHSguPseMeta } from "/vercel/path0/pages/rodzinny-kredyt-mieszkaniowy/termin-konsultacji.vue?macro=true";
import { default as _91uuid_93TGtDiN1L2OMeta } from "/vercel/path0/pages/teleconference/[uuid].vue?macro=true";
import { default as indexy2CdfxePzTMeta } from "/vercel/path0/pages/termin-konsultacji/index.vue?macro=true";
import { default as rankomatcDQDaOIJNyMeta } from "/vercel/path0/pages/termin-konsultacji/rankomat.vue?macro=true";
import { default as test_45layout6Jtd1LgupHMeta } from "/vercel/path0/pages/test-layout.vue?macro=true";
import { default as test_45no_45scriptszMtvVhg9i2Meta } from "/vercel/path0/pages/test-no-scripts.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as index4hgHoMP8ddMeta } from "/vercel/path0/pages/umow-konsultacje/[type]/index.vue?macro=true";
import { default as indexrz6vHOHGQRMeta } from "/vercel/path0/pages/umow-konsultacje/index.vue?macro=true";
import { default as _91uuid_93wgmjcQCVElMeta } from "/vercel/path0/pages/user-offer/[productType]/[uuid].vue?macro=true";
import { default as wspolpracazwNm9EFXWbMeta } from "/vercel/path0/pages/wspolpraca.vue?macro=true";
import { default as indexnmBYIHXifcMeta } from "/vercel/path0/pages/wsrm/index.vue?macro=true";
import { default as component_45stubeSg7A2XeGmMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubeSg7A2XeGm } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_eslint@8.12.0_sass@1.63.6_typescript@5.4.5_vite@5.3.2_vue-tsc@2.0.28_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "agenci-ubezpieczeniowi-city-agent",
    path: "/agenci-ubezpieczeniowi/:city()/:agent()",
    meta: _91agent_93pxrL4iOhkOMeta || {},
    component: () => import("/vercel/path0/pages/agenci-ubezpieczeniowi/[city]/[agent].vue").then(m => m.default || m)
  },
  {
    name: "ankieta",
    path: "/ankieta",
    component: () => import("/vercel/path0/pages/ankieta.vue").then(m => m.default || m)
  },
  {
    name: "banki-dla-ukrainy",
    path: "/banki-dla-ukrainy",
    component: () => import("/vercel/path0/pages/banki-dla-ukrainy.vue").then(m => m.default || m)
  },
  {
    name: "bezpieczny-kredyt",
    path: "/bezpieczny-kredyt",
    component: () => import("/vercel/path0/pages/bezpieczny-kredyt/index.vue").then(m => m.default || m)
  },
  {
    name: "bezpieczny-kredyt-termin-konsultacji",
    path: "/bezpieczny-kredyt/termin-konsultacji",
    component: () => import("/vercel/path0/pages/bezpieczny-kredyt/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "corp",
    path: "/corp",
    component: () => import("/vercel/path0/pages/corp/index.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/vercel/path0/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "dolacz-do-nas",
    path: "/dolacz-do-nas",
    component: () => import("/vercel/path0/pages/dolacz-do-nas.vue").then(m => m.default || m)
  },
  {
    name: "eksperci-finansowi-city-agent",
    path: "/eksperci-finansowi/:city()/:agent(.*)*",
    component: () => import("/vercel/path0/pages/eksperci-finansowi/[city]/[...agent]/index.vue").then(m => m.default || m)
  },
  {
    name: "eksperci-finansowi-city-agent-termin-konsultacji",
    path: "/eksperci-finansowi/:city()/:agent(.*)*/termin-konsultacji",
    component: () => import("/vercel/path0/pages/eksperci-finansowi/[city]/[...agent]/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "eksperci-finansowi",
    path: "/eksperci-finansowi",
    component: () => import("/vercel/path0/pages/eksperci-finansowi/index.vue").then(m => m.default || m)
  },
  {
    name: "elite",
    path: "/elite",
    component: () => import("/vercel/path0/pages/elite/index.vue").then(m => m.default || m)
  },
  {
    name: "ever-ahead",
    path: "/ever-ahead",
    component: () => import("/vercel/path0/pages/ever-ahead.vue").then(m => m.default || m)
  },
  {
    name: "formularz-kontaktowy",
    path: "/formularz-kontaktowy",
    component: () => import("/vercel/path0/pages/formularz-kontaktowy.vue").then(m => m.default || m)
  },
  {
    name: "formularz-type-name",
    path: "/formularz/:type()/:name(.*)*",
    component: () => import("/vercel/path0/pages/formularz/[type]/[...name]/index.vue").then(m => m.default || m)
  },
  {
    name: "formularz-type-name-termin-konsultacji",
    path: "/formularz/:type()/:name(.*)*/termin-konsultacji",
    component: () => import("/vercel/path0/pages/formularz/[type]/[...name]/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "global",
    path: "/global",
    component: () => import("/vercel/path0/pages/global.vue").then(m => m.default || m)
  },
  {
    name: "imobiliare-meet-roomId",
    path: "/imobiliare/meet/:roomId()",
    component: () => import("/vercel/path0/pages/imobiliare/meet/[roomId].vue").then(m => m.default || m)
  },
  {
    name: "imobiliare-teleconference-uuid",
    path: "/imobiliare/teleconference/:uuid()",
    component: () => import("/vercel/path0/pages/imobiliare/teleconference/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "informacje-o-produktach-hipotecznych",
    path: "/informacje-o-produktach-hipotecznych",
    component: () => import("/vercel/path0/pages/informacje-o-produktach-hipotecznych.vue").then(m => m.default || m)
  },
  {
    name: "kalkulator-zdolnosci-kredytowej",
    path: "/kalkulator-zdolnosci-kredytowej",
    component: () => import("/vercel/path0/pages/kalkulator-zdolnosci-kredytowej/index.vue").then(m => m.default || m)
  },
  {
    name: "kalkulator-zdolnosci-kredytowej-termin-konsultacji-oferta",
    path: "/kalkulator-zdolnosci-kredytowej/termin-konsultacji/oferta",
    component: () => import("/vercel/path0/pages/kalkulator-zdolnosci-kredytowej/termin-konsultacji/oferta.vue").then(m => m.default || m)
  },
  {
    name: "kalkulator-zdolnosci-kredytowej-termin-konsultacji-zdolnosc",
    path: "/kalkulator-zdolnosci-kredytowej/termin-konsultacji/zdolnosc",
    component: () => import("/vercel/path0/pages/kalkulator-zdolnosci-kredytowej/termin-konsultacji/zdolnosc.vue").then(m => m.default || m)
  },
  {
    name: "kalkulator-zmiany-oprocentowania-kredytu-hipotecznego",
    path: "/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego",
    component: () => import("/vercel/path0/pages/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego/index.vue").then(m => m.default || m)
  },
  {
    name: "kalkulator-zmiany-oprocentowania-kredytu-hipotecznego-termin-konsultacji",
    path: "/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego/termin-konsultacji",
    component: () => import("/vercel/path0/pages/kalkulator-zmiany-oprocentowania-kredytu-hipotecznego/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "konsultacja-z-ekspertem",
    path: "/konsultacja-z-ekspertem",
    component: () => import("/vercel/path0/pages/konsultacja-z-ekspertem/index.vue").then(m => m.default || m)
  },
  {
    name: "konsultacja-z-ekspertem-kalkulator-kredytu-hipotecznego",
    path: "/konsultacja-z-ekspertem/kalkulator-kredytu-hipotecznego",
    component: () => import("/vercel/path0/pages/konsultacja-z-ekspertem/kalkulator-kredytu-hipotecznego.vue").then(m => m.default || m)
  },
  {
    name: "konsultacja-type",
    path: "/konsultacja/:type()",
    meta: indexgIdZY6iDwkMeta || {},
    component: () => import("/vercel/path0/pages/konsultacja/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "konsultacje-z-ekspertem",
    path: "/konsultacje-z-ekspertem",
    component: () => import("/vercel/path0/pages/konsultacje-z-ekspertem/index.vue").then(m => m.default || m)
  },
  {
    name: "kredyt-hipoteczny-bankSeoName",
    path: "/kredyt-hipoteczny/:bankSeoName()",
    meta: index3JIKLwKmY2Meta || {},
    component: () => import("/vercel/path0/pages/kredyt-hipoteczny/[bankSeoName]/index.vue").then(m => m.default || m)
  },
  {
    name: "kredyt-na-start",
    path: "/kredyt-na-start",
    meta: indexDbjZf3hOK5Meta || {},
    component: () => import("/vercel/path0/pages/kredyt-na-start/index.vue").then(m => m.default || m)
  },
  {
    name: "kredyt-na-start-termin-konsultacji",
    path: "/kredyt-na-start/termin-konsultacji",
    component: () => import("/vercel/path0/pages/kredyt-na-start/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "kredyty-gotowkowe",
    path: "/kredyty-gotowkowe",
    meta: index0HjU9WVYx5Meta || {},
    component: () => import("/vercel/path0/pages/kredyty-gotowkowe/index.vue").then(m => m.default || m)
  },
  {
    name: "kredyty-gotowkowe-termin-konsultacji-oferta",
    path: "/kredyty-gotowkowe/termin-konsultacji/oferta",
    component: () => import("/vercel/path0/pages/kredyty-gotowkowe/termin-konsultacji/oferta.vue").then(m => m.default || m)
  },
  {
    name: "kredyty-hipoteczne",
    path: "/kredyty-hipoteczne",
    meta: indexauXuJiB6LrMeta || {},
    component: () => import("/vercel/path0/pages/kredyty-hipoteczne/index.vue").then(m => m.default || m)
  },
  {
    name: "kredyty-hipoteczne-termin-konsultacji-oferta",
    path: "/kredyty-hipoteczne/termin-konsultacji/oferta",
    component: () => import("/vercel/path0/pages/kredyty-hipoteczne/termin-konsultacji/oferta.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-BaseInstallmentPicker",
    path: "/lendigety/BaseInstallmentPicker",
    component: () => import("/vercel/path0/pages/lendigety/BaseInstallmentPicker.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-CreditworthinessOffersWidget",
    path: "/lendigety/CreditworthinessOffersWidget",
    component: () => import("/vercel/path0/pages/lendigety/CreditworthinessOffersWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-expert-contact-no-faq",
    path: "/lendigety/expert-contact-no-faq",
    component: () => import("/vercel/path0/pages/lendigety/expert-contact-no-faq.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-expert-contact",
    path: "/lendigety/expert-contact",
    component: () => import("/vercel/path0/pages/lendigety/expert-contact.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-FlatForStartFormWidget",
    path: "/lendigety/FlatForStartFormWidget",
    component: () => import("/vercel/path0/pages/lendigety/FlatForStartFormWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-FlatForStartWidget",
    path: "/lendigety/FlatForStartWidget",
    component: () => import("/vercel/path0/pages/lendigety/FlatForStartWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-HalfpageBanner",
    path: "/lendigety/HalfpageBanner",
    component: () => import("/vercel/path0/pages/lendigety/HalfpageBanner.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-LightComparerWidget",
    path: "/lendigety/LightComparerWidget",
    component: () => import("/vercel/path0/pages/lendigety/LightComparerWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-mbwWidget",
    path: "/lendigety/mbwWidget",
    component: () => import("/vercel/path0/pages/lendigety/mbwWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-MeetingPlannerWidget",
    path: "/lendigety/MeetingPlannerWidget",
    component: () => import("/vercel/path0/pages/lendigety/MeetingPlannerWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-MortgageComparerFormWidget",
    path: "/lendigety/MortgageComparerFormWidget",
    component: () => import("/vercel/path0/pages/lendigety/MortgageComparerFormWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-NaviboxBanner",
    path: "/lendigety/NaviboxBanner",
    component: () => import("/vercel/path0/pages/lendigety/NaviboxBanner.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-RefinanceWidget",
    path: "/lendigety/RefinanceWidget",
    component: () => import("/vercel/path0/pages/lendigety/RefinanceWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-SafeLoanFormWidget",
    path: "/lendigety/SafeLoanFormWidget",
    component: () => import("/vercel/path0/pages/lendigety/SafeLoanFormWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-SafeLoanWidget",
    path: "/lendigety/SafeLoanWidget",
    component: () => import("/vercel/path0/pages/lendigety/SafeLoanWidget.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-SimulationFormBusinessInsider",
    path: "/lendigety/SimulationFormBusinessInsider",
    component: () => import("/vercel/path0/pages/lendigety/SimulationFormBusinessInsider.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-SimulationFormFlatForStart",
    path: "/lendigety/SimulationFormFlatForStart",
    component: () => import("/vercel/path0/pages/lendigety/SimulationFormFlatForStart.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-SimulationFormMortgage",
    path: "/lendigety/SimulationFormMortgage",
    component: () => import("/vercel/path0/pages/lendigety/SimulationFormMortgage.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-SimulationFormSafeLoan",
    path: "/lendigety/SimulationFormSafeLoan",
    component: () => import("/vercel/path0/pages/lendigety/SimulationFormSafeLoan.vue").then(m => m.default || m)
  },
  {
    name: "lendigety-WideboardBanner",
    path: "/lendigety/WideboardBanner",
    component: () => import("/vercel/path0/pages/lendigety/WideboardBanner.vue").then(m => m.default || m)
  },
  {
    name: "meet-roomId",
    path: "/meet/:roomId()",
    component: () => import("/vercel/path0/pages/meet/[roomId].vue").then(m => m.default || m)
  },
  {
    name: "michelin",
    path: "/michelin",
    component: () => import("/vercel/path0/pages/michelin/index.vue").then(m => m.default || m)
  },
  {
    name: "o-nas",
    path: "/o-nas",
    component: () => import("/vercel/path0/pages/o-nas.vue").then(m => m.default || m)
  },
  {
    name: "orange",
    path: "/orange",
    component: () => import("/vercel/path0/pages/orange/index.vue").then(m => m.default || m)
  },
  {
    name: "placowki-city",
    path: "/placowki/:city()",
    component: () => import("/vercel/path0/pages/placowki/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: "placowki",
    path: "/placowki",
    component: () => import("/vercel/path0/pages/placowki/index.vue").then(m => m.default || m)
  },
  {
    name: "placowki-termin-konsultacji",
    path: "/placowki/termin-konsultacji",
    component: () => import("/vercel/path0/pages/placowki/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "poradnik-kredytowy",
    path: "/poradnik-kredytowy",
    component: () => import("/vercel/path0/pages/poradnik-kredytowy.vue").then(m => m.default || m)
  },
  {
    name: "rasp",
    path: "/rasp",
    component: () => import("/vercel/path0/pages/rasp/index.vue").then(m => m.default || m)
  },
  {
    name: "rasp-sukces",
    path: "/rasp/sukces",
    component: () => import("/vercel/path0/pages/rasp/sukces.vue").then(m => m.default || m)
  },
  {
    name: "reklamacje",
    path: "/reklamacje",
    component: () => import("/vercel/path0/pages/reklamacje.vue").then(m => m.default || m)
  },
  {
    name: "rodzinny-kredyt-mieszkaniowy",
    path: "/rodzinny-kredyt-mieszkaniowy",
    component: () => import("/vercel/path0/pages/rodzinny-kredyt-mieszkaniowy/index.vue").then(m => m.default || m)
  },
  {
    name: "rodzinny-kredyt-mieszkaniowy-termin-konsultacji",
    path: "/rodzinny-kredyt-mieszkaniowy/termin-konsultacji",
    component: () => import("/vercel/path0/pages/rodzinny-kredyt-mieszkaniowy/termin-konsultacji.vue").then(m => m.default || m)
  },
  {
    name: "teleconference-uuid",
    path: "/teleconference/:uuid()",
    component: () => import("/vercel/path0/pages/teleconference/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "termin-konsultacji",
    path: "/termin-konsultacji",
    component: () => import("/vercel/path0/pages/termin-konsultacji/index.vue").then(m => m.default || m)
  },
  {
    name: "termin-konsultacji-rankomat",
    path: "/termin-konsultacji/rankomat",
    component: () => import("/vercel/path0/pages/termin-konsultacji/rankomat.vue").then(m => m.default || m)
  },
  {
    name: "test-layout",
    path: "/test-layout",
    component: () => import("/vercel/path0/pages/test-layout.vue").then(m => m.default || m)
  },
  {
    name: "test-no-scripts",
    path: "/test-no-scripts",
    component: () => import("/vercel/path0/pages/test-no-scripts.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "umow-konsultacje-type",
    path: "/umow-konsultacje/:type()",
    meta: index4hgHoMP8ddMeta || {},
    component: () => import("/vercel/path0/pages/umow-konsultacje/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "umow-konsultacje",
    path: "/umow-konsultacje",
    component: () => import("/vercel/path0/pages/umow-konsultacje/index.vue").then(m => m.default || m)
  },
  {
    name: "user-offer-productType-uuid",
    path: "/user-offer/:productType()/:uuid()",
    component: () => import("/vercel/path0/pages/user-offer/[productType]/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "wspolpraca",
    path: "/wspolpraca",
    component: () => import("/vercel/path0/pages/wspolpraca.vue").then(m => m.default || m)
  },
  {
    name: "wsrm",
    path: "/wsrm",
    component: () => import("/vercel/path0/pages/wsrm/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubeSg7A2XeGmMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubeSg7A2XeGm
  }
]